var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4hPkJope0iE08hG-EFKoN"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { init, BrowserTracing } from "@sentry/nextjs";
import MPStorage from "@/services/LocalStorage";

const SENTRY_DSN: string =
  process.env.SENTRY_DSN ||
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  "https://32c70d6cc5fa406b86610869c54f252b@o924116.ingest.sentry.io/6326046";

if (
  ["rc", "production", "development"].includes(process.env.NEXT_PUBLIC_APP_ENV)
) {
  init({
    dsn: SENTRY_DSN,
    release: "3.0.0",
    tracesSampleRate: 0.25,
    integrations: [new BrowserTracing()],
    environment: process.env.NEXT_PUBLIC_APP_ENV,
    ignoreErrors: [
      "TypeError: Failed to fetch",
      "TypeError: Load failed",
      "AxiosError: timeout exceeded",
      "AxiosError: Request aborted",
      "Error: Cancel rendering route",
      "https://reactjs.org/docs/error-decoder.html?invariant=422", // There was an error while hydrating this Suspense boundary. Switched to client rendering.
      "https://reactjs.org/docs/error-decoder.html?invariant=423", // There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root...
      "https://reactjs.org/docs/error-decoder.html?invariant=425", // Text content does not match server-rendered HTML...
      "https://reactjs.org/docs/error-decoder.html?invariant=418", // Hydration failed because the initial UI does not match what was rendered on the server.
    ],
    beforeSend(event) {
      if (event.exception) {
        MPStorage.saveToLocalStorage(
          MPStorage.customKeys.ERROR_EVENT_ID,
          event.event_id || ""
        );
      }
      return event;
    },
  });
}
